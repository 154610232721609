@import "~normalize.css/normalize.css";
@import "~nprogress/nprogress.css";
@import "~font-awesome/css/font-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~animate.css/animate.min.css";

@import "./fonts.scss";
/* variables include mixins */
@import "./variables.scss";
@import "./transition.scss";
@import "./sidebar.scss";

/* @import "~element-ui/lib/theme-chalk/index.css"; */
$--font-path: "~element-ui/lib/theme-chalk/fonts";
@import "~element-ui/packages/theme-chalk/src/index";

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;

    padding: 0;
    margin: 0;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}

label {
    font-weight: 700;
}

#app {
    height: 100%;
    width: 100%;
    background-color: $ui-background;
    /* Smoother startup */
    animation: fadein 0.5s;

    > div {
        width: 100%;
        height: 100%;
    }
}

.no-padding {
    padding: 0px !important;
}

.padding-content {
    padding: 4px 0;
}

a:focus,
a:active {
    outline: none;
}

a,
a:focus,
a:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

div:focus {
    outline: none;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.pr-5 {
    padding-right: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.block {
    display: block;
}

.pointer {
    cursor: pointer;
}

.inlineBlock {
    display: block;
}

.clearfix {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

.warn-content {
    background: rgba(66, 185, 131, 0.1);
    border-radius: 2px;
    padding: 16px;
    padding: 1rem;
    line-height: 1.6rem;
    word-spacing: 0.05rem;
    a {
        color: #42b983;
        font-weight: 600;
    }
}

.components-container {
    margin: 30px 50px;
    position: relative;
}

.pagination-container {
    margin-top: 30px;
}

.editor-container .CodeMirror {
    height: 100% !important;
}

.text-center {
    text-align: center;
}

.sub-navbar {
    height: 50px;
    line-height: 50px;
    position: relative;
    width: 100%;
    text-align: right;
    padding-right: 20px;
    transition: 600ms ease position;
    background: linear-gradient(90deg, rgba(32, 182, 249, 1) 0%, rgba(32, 182, 249, 1) 0%, rgba(33, 120, 241, 1) 100%, rgba(33, 120, 241, 1) 100%);
    .subtitle {
        font-size: 20px;
        color: #fff;
    }
    &.draft {
        background: #d0d0d0;
    }
    &.deleted {
        background: #d0d0d0;
    }
}

/** table header filter items / buttons */
.filter-container {
    padding-bottom: 10px;
    .filter-item {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 10px;
        &:not(:first-child):not(:last-child) {
            margin-left: 10px;
        }
    }
}

.link-type,
.link-type:focus {
    color: #337ab7;
    cursor: pointer;
    &:hover {
        color: rgb(32, 160, 255);
    }
}

//refine vue-multiselect plugin
.multiselect {
    line-height: 16px;
}

.multiselect--active {
    z-index: 1000 !important;
}

.tip {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
}

// Download links BOX
.download_link {
    width: 100%;
    float: left;
    text-align: center;
}

.alert_download_item {
    color: $ui-background;
    text-align: center;
}

.alert_download_item_local {
    padding-left: 10px;
}

.unsupported_device {
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
    }

    position: absolute;
    left: 0;
    top: 0;
    background-color: #333333b3;
    height: 100%;
    width: 100%;
    z-index: 9999;
    text-align: center;
    > span {
        position: relative;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-weight: bold;
        width: 50%;
        font-size: 18px;
    }
}

/** Custom full page loading class 
    Example: ./src/app/views/apps/pandora/analysis/components/StartButton/index.vue:
*/
.loading-api {
    .el-icon-loading {
        font-size: 34px;
    }
    .el-loading-spinner .el-loading-text {
        color: #ffffff;
        font-weight: bold;
    }
}

.custom-table-header-labels {
    width: 100%;
    display: inline-block;
    vertical-align: middle;

    .custom-table-header-labels-text {
        text-overflow: ellipsis;
        float: left;
        white-space: nowrap;
        max-width: calc(100% - 17px);
        overflow: hidden;
    }
    span {
        &:nth-of-type(2) {
            float: right;
            // Filter Icon
            i {
                cursor: pointer;
            }
        }
    }
}

.custom-table-header-labels-sortable {
    display: inline-block;
    vertical-align: middle;

    .custom-table-header-labels-text {
        text-overflow: ellipsis;
        float: left;
        white-space: nowrap;
        max-width: calc(100% - 17px);
        overflow: hidden;
    }
    span {
        &:nth-of-type(2) {
            float: right;
            // Filter Icon
            i {
                cursor: pointer;
            }
        }
    }
}

.highlight_code {
    font-family: monospace;
    white-space: pre;
}

.distribution_images {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}

.el-tabs--border-card { 
    border: none;
    box-shadow: none;
    background: transparent;

    .el-tabs__content {
        background: $white;
    }

    .el-tabs__header {
        background-color: transparent;
        border-bottom: none;
        .el-tabs__item {
            color: $ui-background-secondary;
            
            &:is(.is-disabled) {
                color: $ui-background-secondary;
            }
            &:not(.is-disabled) {
            
                border: none;
                &:hover {
                    color: $pink;
                }
            }
            &:is(.is-active) {
                color: $pink;
                font-weight: bold;
                border: none;
            }
        }
    }
}

/* Element redesign code */

.el-tabs__nav-wrap::after {
    background-color: $pink;
}

.el-switch__label {
    color: #FFFFFF;
}

.el-message--warning{
    background-color: #000;
    border: none;

    .el-message__content {
        color: $white;
        font-size: 14px;
    }
}

.el-message .el-icon-warning {
    color: $yellow;
    font-size: 32px;
}
